.custom-button {
    display: inline-flex; /* Use flexbox */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    padding: 10px 20px;
    font-family: 'SWISS 921 BT';
    font-size: 16px;
    width: 80%;
    color: #080756;
    background: linear-gradient(to bottom, #ffb017, #ffc417);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 0 5px #376cfe;
    transition: all 0.1s ease;
    margin-bottom: 15px;
}

.custom-button img {
    margin-right: 10px;
    width: 20px; /* Limit the width of the image */
    height: auto; /* Maintain aspect ratio */
}

/* Active effect on tap */
.custom-button:active {
    box-shadow: 0 0 3px 6px #376cfe;
}
