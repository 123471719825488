/* ModalInput.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: rgba(0, 0, 0, 0);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-content h1 {
  margin: 0;
  margin-bottom: 10px;
}

.modal-content p {
  margin: 0;
  margin-bottom: 20px;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.modal.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Optional: Slight transparency */
  z-index: 1000;
}

.modal.fullscreen iframe {
  width: 100%;
  height: 100%;
  border: none;
  z-index: 1000; /* Ensure it's below the exit button */
}

.exit-icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1001; /* Ensure it’s above the iframe */
}

.exit-icon-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px; /* Adjust to match the size of the exit button */
  height: 120px;
  z-index: 1001;
  pointer-events: auto; /* Ensure the overlay is clickable */
}
