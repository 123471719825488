/* ========================================================================== */
/* React */
/* ========================================================================== */
body > iframe {
    display: none;
}

/* ========================================================================== */
/* Custom  */
/* ========================================================================== */
html {
    background: url('../public/images/GradientBackground.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

body {
    text-align: center;
    background-color: transparent;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-size: calc(10px + 2vmin);
    color: white;
}

.pg-fullscreen {
    position: fixed;
    background: #00000000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 17.5%;
}

.loading-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-page p {
    font-family: 'SWISS 921 BT';
}

.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.toggle-modal-btn {
    position: fixed;
    top: 10px;
    left: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px 12px;
    cursor: pointer;
    z-index: 9999;
}

.unity-content {
    visibility: hidden;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.unity-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.floating-element {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9000;
    /* sits above everything expect for modal */
    color: white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Swiss 921 BT';
}

.exit-icon-container {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    z-index: 9001;
}

.exit-icon-container img {
    max-width: 100%;
}

.loading-progress {
    position: relative;
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-progress::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #fff;
    animation:
        prixClipFix 2s linear infinite,
        rotate 1.7s linear infinite;
    width: 100%;
    height: 100%;
}

.background-video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
}

.background-video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.background-video::-webkit-media-controls {
    display: none !important;
}

.background-video::-webkit-media-controls-enclosure {
    display: none !important;
}

.background-video::-webkit-media-controls-panel {
    display: none !important;
}

.jeopardy-logo {
    width: 100%;
    height: 40%;
    object-fit: contain;
    pointer-events: none;
}

.welcome-screen {
    background-color: transparent;
    z-index: 9999;
}

input[type='text'] {
    /* enlarge by 16/12 = 133.33% */
    border-radius: 6.666666667px;
    font-size: 16px;
    line-height: 26.666666667px;
    padding: 6.666666667px;
    width: 133.333333333%;
    /* scale down by 12/16 = 75% */
    transform: scale(0.75);
    transform-origin: left top;
    /* remove extra white space */
    margin-bottom: -10px;
    margin-right: -33.333333333%;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.disclaimer {
    position: fixed;
    top: 10px;
    left: 10px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.2);
    font-size: 12px;
    user-select: none;
}

/* Fonts */

@font-face {
    font-family: 'Swiss 921 BT';
    src: url('../public/fonts/Swiss_921_BT_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Animations */

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
}
